import React, { useState, lazy } from "react";
import { Container,Row, Col, Button } from "react-bootstrap";
import Image from "../components/widgets/basico/image";
import FormularioBasico from "../components/widgets/formularios/FormularioBasico";
import { FaPhoneAlt, FaWhatsapp, FaRegEnvelope } from "react-icons/fa";

//import Layout from "../components/layouts/layout";
import SEO from "../components/seo";
import { Separador } from "../components/widgets/miscelaneos";

const Layout = lazy(() => import('../components/layouts/layout'));

const subjects = [
    "Arrendamiento",
    "Flotillas",
    "Maquinaria",
    "Refacciones",
    "Rentas",
    "Taller de Colisión",
    "Taller de Servicio",
    "Transporte de Personal",
    "Transportes",
    "Venta de Autos"
  ];

  const agenciaLindaVista = { titulo: "Chevrolet Rivero Linda Vista", direccion: "Av. Miguel Alemán No. 5400, Col. Torres de Linda Vista, Guadalupe, Nuevo León, CP 67138", img: "banner-chevrolet-linda-vista.jpg", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d898.8335676812155!2d-100.2391712!3d25.6933543!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662ebbe71daaebf%3A0xc00ce93fa848615d!2sChevrolet%20Rivero%20Linda%20Vista!5e0!3m2!1ses-419!2smx!4v1602263768511!5m2!1ses-419!2smx" };
  const agenciaGuadalupe = { titulo: "Chevrolet Rivero Guadalupe", direccion: "Carretera a Reynosa, cruz con Ave. México Col. Industrial La Silla, Guadalupe, Nuevo León, CP 67199", img: "banner-chevrolet-guadalupe.jpg", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28765.8653383769!2d-100.2241291!3d25.6801386!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xacb6132bd2ca2dfb!2sChevrolet%20Rivero%20Guadalupe!5e0!3m2!1ses-419!2smx!4v1602276865423!5m2!1ses-419!2smx" };
  const agenciaSantaCatarina = { titulo: "Chevrolet Rivero Santa Catarina", direccion: "Blvd. Gustavo Díaz Ordaz 100-A, Col. La Fama, Santa Catarina, Nuevo León, CP 66100", img: "banner-chevrolet-rivero-santa-catarina.jpg", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3595.91939153924!2d-100.4271026!3d25.6739667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866297e41da4059f%3A0x7e5138597a949a3d!2sChevrolet%20Rivero%20Santa%20Catarina!5e0!3m2!1ses-419!2smx!4v1602278571404!5m2!1ses-419!2smx" };
  const agenciaHumbertoLobo =  { titulo: "Rivero Valle", direccion: "Av. Humberto Lobo #660 cruz con Rio Rhin, Col. Del Valle, San Pedro Garza Garcia, Nuevo León, CP 66220", img: "banner-chevrolet-rivero-valle.jpg", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3596.3988371838573!2d-100.3866046!3d25.6580704!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bd89040ebcb1%3A0x7555c9e7359ccf8c!2sChevrolet%20Rivero%20Humberto%20Lobo!5e0!3m2!1ses-419!2smx!4v1602278901887!5m2!1ses-419!2smx" };
  const agenciaGomezMorin = { titulo: "Rivero Gomez Morin", direccion: "Avenida Gómez Morin 402, Villas de Aragón, San Pedro Garza Garcia, Nuevo León, CP 66273", img: "banner-gomez-morin-rivero.jpg", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3596.697729602825!2d-100.3612372!3d25.6481558!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bddd66850595%3A0x712366af8ab0208a!2sRIVERO%20GOMEZ%20MORIN%20(%20GMC%2C%20Buick%20)!5e0!3m2!1ses-419!2smx!4v1602282204138!5m2!1ses-419!2smx" };
  const agenciaVasconcelos = { titulo: "Rivero Vasconcelos Taller de Servicio", direccion: "Av. José Vasconcelos #1555 Entre Neil Armstrong y Av. De los Conquistadores Col. Del Valle, San Pedro Garza Garcia, Nuevo León, CP 66220", img: "banner-chevrolet-servicio-vasconcelos.jpg", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3596.4421646573264!2d-100.3911147!3d25.6566334!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bd9b5fcb1ef7%3A0x13a53b6fd659748!2sTaller%20De%20Servicio%20Rivero%20Vasconcelos!5e0!3m2!1ses-419!2smx!4v1602282426260!5m2!1ses-419!2smx" };
  const agenciaJuarez = { titulo: "Chevrolet Rivero Juarez", direccion: "Av. Lic. Arturo B. de La Garza, 20 de Septiembre, Juarez, Nuevo León, CP 67250", img: "banner-chevrolet-juarez.jpg", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d86198.15690589877!2d-100.15037983312293!3d25.682979662721472!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662c39abac494f5%3A0x206d8ae43dd521f4!2sChevrolet%20Rivero%20Paseo%20Ju%C3%A1rez!5e0!3m2!1ses-419!2smx!4v1737660302980!5m2!1ses-419!2smx" };

export default function Contacto(props) {

    const parameters = props.location.search;

    const [subject, setSubject] = useState(subjects[subjects.length - 1]);

    return (
        <Layout selectedMenuItem={"horario"} parameters={parameters}>
            <SEO
                title="Grupo Rivero - Agencia Chevrolet - Quiénes somos"
                description="Grupo Rivero - Agencia Chevrolet - Chevrolet Monterrey, Chevrolet Linda Vista, Chevrolet Guadalupe, Chevrolet Santa Catarina, Humbrto Lobo - Aveo, Beat, Spark, Onix"
                keywords="Agencia Chevrolet, Agencia de autos, chevrolet monterrey, Chevrolet Linda Vista, Chevrolet Guadalupe, Chevrolet Santa catarina, Aveo, Beat, Spark, Onix"
                path="/contacto" />

            <div className="banner-quienes-somos">
                <div className="sombra-banner-quienes-somos">
                    <div className="mt-4 p-4">
                        <p className="mb-2">Chevrolet</p>
                        <h1 className="titulo-quienes-somos">GRUPO RIVERO</h1>
                        <br></br>
                        <h4>Telefonos, Horarios y Direcciones</h4>
                    </div>
                </div>
            </div>

            <Container  className="p-4">
                <center>
                    <h2 className="text-white">Contacta a tu Asesor de Confianza</h2>
                </center>
                <div className="mt-4 mb-2"><Separador /></div>
            </Container>

            <Container >
                <Row>
                    <Col md={4}>
                        <a href="tel:8112571257" >
                            <Row>
                                <Col md={12} className="pb-2">
                                    <div className="estilo-tel-icono"><FaPhoneAlt size={30} /></div>
                                </Col>
                                <Col md={12}>
                                    <center><p><strong>Teléfono</strong></p></center>
                                </Col>
                                <Col md={12}>
                                    <center><p>(81) 1257-1257</p></center>
                                </Col>
                            </Row>
                        </a>
                    </Col>
                    <Col md={4}>
                        <a href="https://wa.me/+52181116001600" >
                            <Row>
                                <Col md={12} className="pb-2">
                                    <div className="estilo-wpp-icono"><FaWhatsapp size={30} /></div>
                                </Col>
                                <Col md={12}>
                                    <center><p><strong>Whatsapp</strong></p></center>
                                </Col>
                                <Col md={12}>
                                    <center><p>(81) 1600-1600</p></center>
                                </Col>
                            </Row>
                        </a>
                    </Col>
                    <Col md={4}>
                        <a href="mailto:informes@gruporivero.com" >
                            <Row>
                                <Col md={12} className="pb-2">
                                    <div className="estilo-correo-icono"><FaRegEnvelope size={30} /></div>
                                </Col>
                                <Col md={12}>
                                    <center><p><strong>Correo</strong></p></center>
                                </Col>
                                <Col md={12}>
                                    <center><p>informes@gruporivero.com</p></center>
                                </Col>
                            </Row>
                        </a>
                    </Col>
                </Row>
            </Container>

            <>
                <Container className="bg-white mb-4 px-4 pt-4">
                    <Agencia props={agenciaLindaVista}/>
                    <ContHorarios/>
                    <Row>
                            <Col md={4} className="mb-5">
                                <center>
                                    <h5>Body Shop</h5>
                                    <p>Lunes - Viernes 9:00 AM - 6:30 PM</p>
                                    <p>Sábado 9:00 AM - 2:00 PM</p>
                                    <p>Domingo Cerrado</p>
                                </center>
                            </Col>
                            <Col md={4} className="mb-5">
                                <center>
                                    <h5>Renta de Autos</h5>
                                    <p>Lunes - Viernes 9:00 AM - 7:00 PM</p>
                                    <p>Sábado 10:00 AM - 2:00 PM</p>
                                    <p>Domingo Cerrado</p>
                                </center>
                            </Col>
                    </Row>
                </Container>
                <Container className="bg-white mb-4  px-4 pt-4">
                    <Agencia props={agenciaGuadalupe}/>
                    <ContHorarios/>
                </Container>
                <Container className="bg-white mb-4  px-4 pt-4">
                    <Agencia props={agenciaSantaCatarina}/>
                    <ContHorarios/>
                </Container>
                <Container className="bg-white mb-4  px-4 pt-4">
                    <Agencia props={agenciaJuarez}/>
                    <ContHorariosBasico/>
                </Container>
                 {<Container className="bg-white mb-4  px-4 pt-4">
                   <Agencia props={agenciaHumbertoLobo}/>
                    <Row>
                            <Col md={6} className="mb-5">
                                <center>
                                    <h5>Horario Ventas</h5>
                                    <p>Lunes - Viernes 9:00 AM - 8:00 PM</p>
                                    <p>Sábado 9:00 AM - 6:30 PM</p>
                                    <p>Domingo 10:00 AM - 6:00 PM</p>
                                </center>
                            </Col>
                            <Col md={6} className="mb-5">
                                <center>
                                    <h5>Refacciones</h5>
                                    <p>Lunes - Sábado 7:00 AM - 7:00 PM</p>
                                    <p>Domingo 9:00 AM - 3:00 PM</p>
                                </center>
                            </Col>
                    </Row>
                </Container>}
                <Container className="bg-white mb-4  px-4 pt-4">
                    <Agencia props={agenciaGomezMorin}/>
                    <ContHorarios/>
                </Container>
                <Container className="bg-white mb-4  px-4 pt-4">
                    <Agencia props={agenciaVasconcelos}/>
                    <Row>
                            <Col md={12} className="mb-5">
                                <center>
                                    <h5>Horario de Servicio</h5>
                                    <p>Lunes - Viernes 8:00 AM - 7:00 PM</p>
                                    <p>Sábado 9:00 AM - 2:00 PM</p>
                                    <p>Domingo Cerrado</p>
                                </center>
                            </Col>
                    </Row>
                </Container>
            </>

            <div className="d-flex justify-content-center">
                <Row className="bg-white mb-2" style={{width: '90%'}}>
                    <div className="containerFormContactanos">
                        <Row style={{width: '100%'}}>
                            <center className="pb-3">
                                <h1>Contáctanos</h1>
                                <Separador/>
                            </center>
                            <Col xs={12} md={12} lg={6}>
                                <FormularioBasico props={props}>
                                    <div className="form-group pb-3">
                                        <select
                                            value={subject}
                                            className="form-control"
                                            name="subject"
                                            id="subject"
                                            onChange={e => setSubject(e.target.value)}
                                        >
                                            {subjects.map(s => <option value={s} key={s}>{s}</option>)}
                                        </select>
                                    </div>
                                </FormularioBasico>
                            </Col>
                            <Col xs={12} md={12} lg={6}>
                                <Image fileName={"chevrolet-contactanos-formulario"}/>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>
        </Layout>
    )
}

function Agencia({props}) {
    //console.log("Estamos en agencia", props);
    return(
        <Row>
            <Col md={7}>
                <Image fileName={props.img}/>
            </Col>
            <Col md={5}>
                <iframe src={props.map}
                    width="100%" height={247}
                    title="ContImgMap"
                    frameBorder={0} style={{border: 0}}
                    allowFullScreen aria-hidden="false" />
            </Col>
            <Col md={12} className="pt-3">
                <center>
                <h4>{props.titulo}</h4>
                <p>{props.direccion}</p>
                </center>
            </Col>
        </Row>
    )
}

function ContHorarios() {
    return (
        <Row>
            <Col md={4} className="mb-2">
                <center>
                    <h5>Horario Ventas</h5>
                    <p>Lunes - Viernes 9:00 AM - 8:00 PM</p>
                    <p>Sábado 9:00 AM - 6:30 PM</p>
                    <p>Domingo 10:00 AM - 6:00 PM</p>
                </center>
            </Col>
            <Col md={4} className="mb-2">
                <center>
                    <h5>Taller de Servicio</h5>
                    <p>Lunes - Sábado 7:00 AM - 7:00 PM</p>
                    <p>Domingo 9:00 AM - 3:00 PM</p>
                </center>
            </Col>
            <Col md={4} className="mb-2">
                <center>
                    <h5>Refacciones</h5>
                    <p>Lunes - Sábado 7:00 AM - 7:00 PM</p>
                    <p>Domingo 9:00 AM - 3:00 PM</p>
                </center>
            </Col>  
        </Row>
    )
}

function ContHorariosBasico() {
    return (
        <Row>
            <Col md={12} className="mb-2">
                <center>
                    <h5>Horario Ventas</h5>
                    <p>Lunes - Viernes 9:00 AM - 8:00 PM</p>
                    <p>Sábado y Domingo  10:00 AM - 7:00 PM</p>
                </center>
            </Col>
        </Row>
    )
}